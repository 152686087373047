import { Link } from "react-router-dom";
import { PlaylistModel } from "../../domains/playlist/playlist.types";
import styled from "styled-components";
import { t } from "i18next";
import Loader from "../Loader";

export default function MiniPlaylistScroller({
  title,
  linkTo,
  playlists,
  isLoading,
}: {
  title: string;
  linkTo: string;
  playlists: PlaylistModel[];
  isLoading: boolean;
}) {
  return (
    <Container className="mini-playlist-scroller-container">
      <ScrollerHeader>
        <h3>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M18.75 12.5L13.75 15.625V9.375L18.75 12.5Z" fill="#FFC408" />
            <path d="M2.5 5C2.5 4.83424 2.56585 4.67527 2.68306 4.55806C2.80027 4.44085 2.95924 4.375 3.125 4.375H16.875C17.0408 4.375 17.1997 4.44085 17.3169 4.55806C17.4342 4.67527 17.5 4.83424 17.5 5C17.5 5.16576 17.4342 5.32473 17.3169 5.44194C17.1997 5.55915 17.0408 5.625 16.875 5.625H3.125C2.95924 5.625 2.80027 5.55915 2.68306 5.44194C2.56585 5.32473 2.5 5.16576 2.5 5ZM10.625 9.375H3.125C2.95924 9.375 2.80027 9.44085 2.68306 9.55806C2.56585 9.67527 2.5 9.83424 2.5 10C2.5 10.1658 2.56585 10.3247 2.68306 10.4419C2.80027 10.5592 2.95924 10.625 3.125 10.625H10.625C10.7908 10.625 10.9497 10.5592 11.0669 10.4419C11.1842 10.3247 11.25 10.1658 11.25 10C11.25 9.83424 11.1842 9.67527 11.0669 9.55806C10.9497 9.44085 10.7908 9.375 10.625 9.375ZM10.625 14.375H3.125C2.95924 14.375 2.80027 14.4408 2.68306 14.5581C2.56585 14.6753 2.5 14.8342 2.5 15C2.5 15.1658 2.56585 15.3247 2.68306 15.4419C2.80027 15.5592 2.95924 15.625 3.125 15.625H10.625C10.7908 15.625 10.9497 15.5592 11.0669 15.4419C11.1842 15.3247 11.25 15.1658 11.25 15C11.25 14.8342 11.1842 14.6753 11.0669 14.5581C10.9497 14.4408 10.7908 14.375 10.625 14.375ZM19.375 12.5C19.3749 12.606 19.348 12.7101 19.2966 12.8028C19.2452 12.8955 19.1711 12.9735 19.0813 13.0297L14.0813 16.1547C13.9867 16.2138 13.878 16.2465 13.7665 16.2495C13.655 16.2524 13.5447 16.2255 13.4472 16.1714C13.3496 16.1174 13.2683 16.0382 13.2116 15.9421C13.155 15.846 13.1251 15.7365 13.125 15.625V9.375C13.1251 9.26346 13.155 9.15397 13.2116 9.05789C13.2683 8.9618 13.3496 8.88263 13.4472 8.82858C13.5447 8.77454 13.655 8.74759 13.7665 8.75053C13.878 8.75347 13.9867 8.7862 14.0813 8.84531L19.0813 11.9703C19.1711 12.0265 19.2452 12.1045 19.2966 12.1972C19.348 12.2899 19.3749 12.394 19.375 12.5ZM17.5711 12.5L14.375 10.5023V14.4977L17.5711 12.5Z" fill="#212121" />
          </svg>
          {title}
        </h3>
        {playlists.length > 0 && (
          <Link to={linkTo}>
            <span>{t("common:action.seeAll")}</span>
          </Link>
        )}
      </ScrollerHeader>

      <HScrollContainer className="scrollbar-hidden">
        {isLoading ? (
          <Loader loaderOnly />
        ) : (
          <>
            {!playlists.length ? (
              <span className="no-results">{t("common:noPlaylists")}</span>
            ) : (
              <>
                {playlists.slice(0, 5).map((playlist) => (
                  <ItemContainer key={playlist._id}>
                    <Link to={`/playlist/public/${playlist._id}`}>
                      <span>{playlist.title}</span>
                    </Link>
                  </ItemContainer>
                ))}
                {/* <Link to={linkTo}>
                  <ItemContainer className="add">
                    <span className="add">{t("common:seeAllPlaylists")}</span>
                  </ItemContainer>
                </Link> */}
              </>
            )}
          </>
        )}
      </HScrollContainer>
    </Container>
  );
}

const Container = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .no-results {
    font-family: "Inter";
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.24px;
    color: #212121;
  }
`;

const ScrollerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;

  h3 {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #212121;
    font-family: "Inter";
    font-size: 21px;
    font-weight: 700;
    margin: 0;
  }

  a {
    font-family: "Inter";
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.24px;
    text-decoration-line: underline;
    color: #ce0868;
    margin: 0;
  }
`;

const HScrollContainer = styled.div`
  overflow-x: auto;
  padding: 0 24px;
  display: flex;
  align-items: stretch;
  gap: 8px;
`;

const ItemContainer = styled.div`
  display: flex;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  background: #fddeee;

  &.add {
    background: #212121;
  }

  span {
    color: #212121;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 600;
    line-height: 110%;
    white-space: nowrap;

    &.add {
      color: #ffffff;
    }
  }
`;
