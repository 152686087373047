import { atom, useAtom } from "jotai";
import { PlaylistModel } from "../playlist.types";
import { DebugLogger } from "../../app/utils/debugLogger";

const debug = new DebugLogger("Playlist Cache");
debug.mute();

export enum Cache {
  RECOMMENDED = "recommended",
  CERTIFIED = "certified",
  HOME_LIST = "home_list",
  ROOM_SCROLLER = "room_scroller",
  ROOM_LIST = "room_list",
}

interface PlaylistCacheData {
  list: PlaylistModel[];
  total: number;
}

interface CacheLists {
  [Cache.RECOMMENDED]: PlaylistCacheData;
  [Cache.CERTIFIED]: PlaylistCacheData;
  [Cache.HOME_LIST]: PlaylistCacheData;
  [Cache.ROOM_SCROLLER]: PlaylistCacheData;
  [Cache.ROOM_LIST]: PlaylistCacheData;
}

const defaultCacheLists: CacheLists = {
  [Cache.RECOMMENDED]: { list: [], total: 0 },
  [Cache.CERTIFIED]: { list: [], total: 0 },
  [Cache.HOME_LIST]: { list: [], total: 0 },
  [Cache.ROOM_SCROLLER]: { list: [], total: 0 },
  [Cache.ROOM_LIST]: { list: [], total: 0 },
};

const cacheListsAtom = atom<CacheLists>(defaultCacheLists);

function usePlaylistCache(cacheId: Cache) {
  const [cacheLists, setCacheLists] = useAtom(cacheListsAtom);

  function saveCache(listType: Cache, docs: PlaylistModel[], total: number) {
    debug.log("Save cache", { listType, docs, total });
    setCacheLists((lists) => ({ ...lists, [listType]: { list: docs, total } }));
  }

  function findCachedPlaylist(playlistId: string) {
    const mergedCachePlaylists = Object.values(cacheLists).flat().map((cache: PlaylistCacheData) => cache.list).flat();
    return mergedCachePlaylists.find((playlist: PlaylistModel) => {
      return playlist._id === playlistId;
    });
  }

  function updatePlaylistInAllCaches(playlist: PlaylistModel) {
    Object.values(cacheLists).forEach((cache: PlaylistCacheData) => {
      const cachedPlaylist = cache.list.find((p: PlaylistModel) => p._id === playlist._id);
      if (cachedPlaylist) {
        cachedPlaylist.playlist = playlist.playlist;
        cachedPlaylist.playlistContent = playlist.playlistContent;
      }
    });
  }

  return {
    list: cacheLists[cacheId].list,
    total: cacheLists[cacheId].total,
    update: (playlists: PlaylistModel[], total: number) => {
      saveCache(cacheId, playlists, total);
    },
    // Utility functions accessing all caches
    findCachedPlaylist,
    updatePlaylistInAllCaches,
    clearAllCaches: () => setCacheLists(defaultCacheLists),
  };
}

// Public playlists (discovery)
export function usePlaylistCacheRecommended() {
  return usePlaylistCache(Cache.RECOMMENDED);
}

// Certified/KOL playlists (discovery)
export function usePlaylistCacheCertified() {
  return usePlaylistCache(Cache.CERTIFIED);
}

// Playlists displayed in scroller (rooms)
export function usePlaylistCacheRoomScroller() {
  return usePlaylistCache(Cache.ROOM_SCROLLER);
}

// Playlists displayed in the list page (discovery)
export function usePlaylistCacheHomeList() {
  return usePlaylistCache(Cache.HOME_LIST);
}

// Playlists displayed in the list page (rooms)
export function usePlaylistCacheRoomList() {
  return usePlaylistCache(Cache.ROOM_LIST);
}
