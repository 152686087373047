import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { usePlaylistCacheHomeList, usePlaylistCacheRoomList } from "../../domains/playlist/hooks/usePlaylistCache";
import { useReadyState } from "../../domains/app/hooks/useReadyState";
import { usePageScroll } from "../../domains/app/hooks/usePageScroll";
import { PlaylistModel } from "../../domains/playlist/playlist.types";
import { getPublicPlaylists } from "../../domains/playlist/endpoints/getPublicPlaylists";
import { getCertifiedPlaylists } from "../../domains/playlist/endpoints/getCertifiedPlaylists";
import { getStoredContentFilters } from "../../domains/app/utils/contentFilters";
import styled from "styled-components";
import { displayToast } from "../../components/app/AppToast";
import Loader from "../../components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import PlaylistCard from "../../components/playlists/PlaylistCard";
import { SupportedLanguage } from "../../interfaces";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { gaEventViewItemListPlaylist } from "../../tools/analytics/playlistAnalytics";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  HeaderTitle,
  NavBack,
  NavLang,
} from "../../components/app/headers/layout";
import BottomNavbar from "../../components/app/BottomNavbar";
import ContentFilterButton from "../../components/filters/ContentFilterButton";
import SpecialtyFilterPanel from "../../components/filters/SpecialtyFilterPanel";
import disableScroll from "disable-scroll";
import ItemList from "../../components/app/ItemList";
import FadeIn from "react-fade-in/lib/FadeIn";
import { useRoomContext } from "../../domains/app/contexts/room.context";

export default function PlaylistsListPage() {
  const location = useLocation();
  const { isInRoom } = useRoomContext();
  const { activeLang, t } = useLangContext();
  const homeListCache = usePlaylistCacheHomeList();
  const roomListCache = usePlaylistCacheRoomList();
  const cachedPlaylists = !isInRoom ? homeListCache.list : roomListCache.list;
  const totalCachedPlaylists = !isInRoom ? homeListCache.total : roomListCache.total;
  const updateCache = !isInRoom ? homeListCache.update : roomListCache.update;
  const { setAsReady, useReadyEffect } = useReadyState();
  const { savePageScrollY, restorePageScrollY } = usePageScroll();
  const [specialtyFilters, setSpecialtyFilters] = useState<string[]>(
    getStoredContentFilters().specialtyIds ?? []
  );
  const [isLoading, setLoading] = useState(true);
  const [isFilterPanelVisible, setFilterPanelVisible] = useState(false);
  const isFiltersAvailable = !isInRoom;

  useEffect(() => {
    (async function () {
      disableScroll.off();

      if (cachedPlaylists.length > 0) {
        setLoading(false);
        restorePageScrollY();
      } else {
        scrollTo(0, 0);
        loadPlaylists(true);
      }
      setAsReady();
    })();
  }, []);

  useReadyEffect(() => {
    scrollTo(0, 0);
    loadPlaylists(true);
  }, [activeLang, specialtyFilters]);

  useEffect(() => {
    if (cachedPlaylists.length) {
      gaEventViewItemListPlaylist({
        playlists: cachedPlaylists,
        listName: AnalyticsItemList.HOME,
      });
    }
  }, [cachedPlaylists]);

  async function loadPlaylists(resetList = false) {
    try {
      const params: {
        limit: number;
        offset: number;
        language: SupportedLanguage;
        medicalSpecialties?: string;
      } = {
        limit: 10,
        offset: resetList ? 0 : cachedPlaylists.length,
        language: activeLang,
      };

      if (specialtyFilters.length > 0) {
        params.medicalSpecialties = specialtyFilters.join(",");
      }

      const searchParams = new URLSearchParams(location.search);

      if (searchParams.get("kol") === "true") {
        const { docs, meta } = await getCertifiedPlaylists(params);
        const newList = resetList ? docs : [...cachedPlaylists, ...docs];
        updateCache(newList, meta.total);
      } else {
        const { docs, meta } = await getPublicPlaylists(params);
        const newList = resetList ? docs : [...cachedPlaylists, ...docs];
        updateCache(newList, meta.total);
      }

      setLoading(false);
    } catch (error) {
      console.error("Couldn't fetch content.", error);
      displayToast(t("error:default"));
    }
  }

  function handleChangeFilters() {
    setSpecialtyFilters(
      getStoredContentFilters().specialtyIds ?? specialtyFilters
    );
  }

  return (
    <>
      <Wrapper>
        <HeaderBoxAutoSafe>
          <HeaderLinks>
            <NavBack />
          </HeaderLinks>
          <HeaderTitle>{t("content:type.playlists")}</HeaderTitle>
          <HeaderLinks>
            <NavLang />
          </HeaderLinks>
        </HeaderBoxAutoSafe>

        {isFiltersAvailable ? (
          <ContentFilterButton
            active={!!specialtyFilters?.length}
            onClick={() => setFilterPanelVisible(true)}
          />
        ) : (
          <div style={{ height: 16 }} />
        )}

        <div style={{ flex: 1 }}>
          {isLoading ? (
            <Loader loaderOnly />
          ) : !cachedPlaylists.length ? (
            <NoResult>{t("common:noContent")}</NoResult>
          ) : (
            <FadeIn key="content">
              <InfiniteScroll
                dataLength={cachedPlaylists.length}
                next={loadPlaylists}
                hasMore={cachedPlaylists.length < totalCachedPlaylists}
                loader={null}
              >
                <ItemList>
                  {cachedPlaylists.map((playlist: PlaylistModel) => (
                    <PlaylistCard
                      key={playlist._id + "pagecontentkey"}
                      playlist={playlist}
                      onClick={() => {
                        savePageScrollY();
                      }}
                    />
                  ))}
                </ItemList>
              </InfiniteScroll>
            </FadeIn>
          )}
        </div>

        <BottomNavbar />
      </Wrapper>

      <SpecialtyFilterPanel
        isOpen={isFilterPanelVisible}
        onClose={() => setFilterPanelVisible(false)}
        onSave={() => handleChangeFilters()}
      />
    </>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  background: #fae6f0;
`;

const NoResult = styled.div`
  padding: 8px 22px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 110%;
  color: #212121;
`;
