import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useRoomContext } from "../../domains/app/contexts/room.context";
import { usePlaylistCacheRoomScroller } from "../../domains/playlist/hooks/usePlaylistCache";
import { usePageScroll } from "../../domains/app/hooks/usePageScroll";
import { useArticleSwipe } from "../../domains/article/hooks/useArticleSwipe";
import { ArticleModel } from "../../domains/article/article.types";
import { PlaylistModel } from "../../domains/playlist/playlist.types";
import { RoomModel } from "../../domains/room/room.types";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { getPublicPlaylists } from "../../domains/playlist/endpoints/getPublicPlaylists";
import { useLazyGetArticlesListQuery } from "../../domains/article/endpoints/getArticlesList";
import { useRoomPreflight } from "../../domains/room/hooks/useRoomPreflight";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { getStoredRoomLang } from "../../domains/app/utils/langUtils";
import { ArticleCacheList, useArticleCache } from "../../domains/article/hooks/useArticleCache";
import disableScroll from "disable-scroll";
import Loader from "../../components/Loader";
import FadeIn from "react-fade-in/lib/FadeIn";
import InfiniteScroll from "react-infinite-scroll-component";
import SkeletonContentCard from "../../components/profile/SkeletonContentCard";
import LanguagePanel from "../../components/app/LanguagePanel";
import SafeAreaTopWrapper from "../../components/app/SafeAreaTopWrapper";
import {
  HeaderBoxSimple,
  HeaderLinks,
  NavHome,
  NavLang,
  NavOptions,
} from "../../components/app/headers/layout";
import {
  RoomHeader,
  RoomListHeading,
  RoomListWrapper,
  RoomPageTab,
  RoomPageWrapper,
  RoomTabSwitcher,
} from "../../components/room/layout";
import RoomNectarScroller from "../../components/room/RoomNectarScroller";
import ArticleCard from "../../components/cards/ArticleCard";
import MiniPlaylistScroller from "../../components/app/MiniPlaylistScroller";
import styled from "styled-components";
import RoomOptionsPanel from "../../components/room/RoomOptionsPanel";
import DownloadAppModal from "../../components/home/modals/DownloadAppModal";
import CustomIcon from "../../components/CustomIcon";
import { ReactComponent as FeedIcon } from "../../assets/icons/feed-white.svg";
import { ReactComponent as BrainIcon } from "../../assets/icons/brain-green.svg";
import TakedaDisclaimerModal from "../../components/room/TakedaDisclaimerModal";
import { isNativeIOS } from "../../tools/ios";
import quizCardImgSrc from "../../assets/images/quiz-card-body-pain.png"; // TODO: real image URL.
import ContentFilterButton from "../../components/filters/ContentFilterButton";
import { getStoredContentFilters } from "../../domains/app/utils/contentFilters";
import SpecialtyFilterPanel from "../../components/filters/SpecialtyFilterPanel";
import SubSpecialtyFilterPanel from "../../components/filters/SubSpecialtyFilterPanel";

export default function RoomDiscoveryPage() {
  const history = useHistory();
  const { roomId } = useParams<{ roomId: string }>();
  const { activeLang, t } = useLangContext();
  const { isInRoom, currentRoom } = useRoomContext();
  const { pageScrollY, setPageScrollY } = usePageScroll();
  const { isLoadingRoom, preflightRoom } = useRoomPreflight(roomId);
  const [isLangSelected, setLangSelected] = useState(
    getStoredRoomLang(roomId) !== null
  );
  const roomScrollerCache = usePlaylistCacheRoomScroller();
  const articleCache = useArticleCache();
  const cachedArticles = articleCache.cacheLists[ArticleCacheList.ROOM];
  const { setSwipeCache } = useArticleSwipe();
  const [getArticlesList] = useLazyGetArticlesListQuery();
  const [playlists, setPlaylists] = useState<PlaylistModel[]>([]);
  const [articles, setArticles] = useState<ArticleModel[]>([]);
  const [isLoadingArticles, setLoadingArticles] = useState(true);
  const [isLoadingPlaylists, setLoadingPlaylists] = useState(true);
  // NOTE: Using state here instead of RTK Query state to prevent glitch before first fetch.
  const [totalArticles, setTotalArticles] = useState(0);
  const [specialtyFilters, setSpecialtyFilters] = useState<string[]>(getStoredContentFilters().specialtyIds ?? []);
  const [subSpecialtyFilters, setSubSpecialtyFilters] = useState<string[]>(getStoredContentFilters().subSpecialtyIds ?? []);
  const [isOptionsPanelVisible, setOptionsPanelVisible] = useState(false);
  const [isFilterPanelVisible, setFilterPanelVisible] = useState(false);
  const [isDisclaimerVisible, setDisclaimerVisible] = useState(false);
  const [isTakedaRoom, setIsTakedaRoom] = useState(false);
  const isFiltersAvailable = currentRoom?.organisation?.organisationSettings?.discoveryFilter ?? false;
  const isDebugMode = process.env.NODE_ENV === "development" || window.location.host.startsWith("dev");
  // TODO: real availability check.
  const isQuizAvailable = isDebugMode
    ? true
    : currentRoom?.name.toLowerCase().includes("body pain");
  // const isQuizAvailable = false;

  useEffect(() => {
    (async function () {
      try {
        disableScroll.off();
        await preflightRoom();
      } catch (error) {
        console.error("Couldn't load room.", error);
        history.push("/discovery");
      }
    })();
  }, []);

  useEffect(() => {
    if (isInRoom && activeLang) {
      if (isLoadingPlaylists) {
        // NOTE: Only occurs on first mount
        if (playlists.length === 0 && roomScrollerCache.list.length >= 20) {
          setPlaylists(roomScrollerCache.list);
          setLoadingPlaylists(false);
        } else {
          loadPlaylists(true);
        }
      } else {
        // NOTE: Only occurs after first mount
        scrollTo(0, 0);
        setLoadingPlaylists(true);
        loadPlaylists(true);
      }

      if (isLoadingArticles) {
        // NOTE: Only occurs on first mount
        if (articles.length === 0 && cachedArticles.length >= 20) {
          setArticles(cachedArticles);
          setTotalArticles(1000); // NOTE: Force fetch when scrolling to the bottom of the list.
          setLoadingArticles(false);
          // clearSwipeCache(); // TODO: Check if necessary?
          setTimeout(() => {
            scrollTo(0, pageScrollY);
          }, 100);
        } else {
          loadArticles(true);
        }
      } else {
        // NOTE: Only occurs after first mount
        scrollTo(0, 0);
        setLoadingArticles(true);
        loadArticles(true);
      }
    }
  }, [isInRoom, activeLang, specialtyFilters, subSpecialtyFilters]);

  useEffect(() => {
    if (
      currentRoom &&
      currentRoom.organisation.uid.toLowerCase().includes("takeda")
    ) {
      setIsTakedaRoom(true);
      const hasAcceptedDisclaimer =
        localStorage.getItem("acceptedRulesTakedaRoom") === "true";

      if (!hasAcceptedDisclaimer) {
        setDisclaimerVisible(true);
      }
    } else {
      setIsTakedaRoom(false);
    }
  }, [currentRoom]);

  async function loadPlaylists(resetList = false) {
    try {
      getPublicPlaylists({
        limit: 20,
        offset: resetList ? 0 : playlists.length,
        language: activeLang,
      })
        .then(({ docs, total }) => {
          const newList = resetList ? docs : [...playlists, ...docs];
          setPlaylists(newList);
          roomScrollerCache.update(docs, total);
          setLoadingPlaylists(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error("Couldn't load playlists.", error);
    }
  }

  async function loadArticles(resetList = false) {
    try {
      const params: any = {
        limit: 20,
        offset: resetList ? 0 : articles.length,
        language: activeLang,
      };

      if (specialtyFilters.length > 0) {
        params.medicalSpecialties = specialtyFilters.join(",");
      }

      if (subSpecialtyFilters.length > 0) {
        params.tags = subSpecialtyFilters.join(",");
      }

      getArticlesList(params)
        .unwrap()
        .then(({ docs, total }) => {
          const newList = resetList ? docs : [...articles, ...docs];
          setArticles(newList);
          setTotalArticles(total);
          articleCache.saveList(ArticleCacheList.ROOM, docs);
          setLoadingArticles(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error("Couldn't load articles.", error);
    }
  }

  function handleDisclaimerAccept() {
    setDisclaimerVisible(false);
    localStorage.setItem("acceptedRulesTakedaRoom", "true");
  }

  function handleChangeFilters() {
    setSpecialtyFilters(getStoredContentFilters().specialtyIds ?? specialtyFilters);
    setSubSpecialtyFilters(getStoredContentFilters().subSpecialtyIds ?? subSpecialtyFilters);
  }

  if (isLoadingRoom) return <Loader />;

  if (!isLangSelected) {
    return (
      <LanguagePanel
        isVisible={!isLangSelected}
        onClose={() => {
          setLangSelected(true);
        }}
        displayConfirmButton={true}
      />
    );
  }

  return (
    <>
      <Wrapper style={{ backgroundColor: currentRoom?.primaryColor ?? "" }}>
        <SafeAreaTopWrapper>
          <HeaderBoxSimple>
            <HeaderLinks>
              <NavHome />
            </HeaderLinks>
            <RoomHeader room={currentRoom as RoomModel} />
            <HeaderLinks>
              <NavLang />
              <NavOptions onClick={() => setOptionsPanelVisible(true)} />
            </HeaderLinks>
          </HeaderBoxSimple>

          <RoomTabSwitcher activeTab={RoomPageTab.DISCOVERY} />
        </SafeAreaTopWrapper>

        <RoomNectarScroller />

        {/* TODO: Link see all */}
        <MiniPlaylistScroller
          title={t("room:discovery.playlists")}
          linkTo={`/room/playlists/${roomId}`}
          playlists={playlists}
          isLoading={isLoadingPlaylists}
        />

        {isQuizAvailable && (
          <>
            {/* <a
              style={{
                margin: "32px 16px 16px",
                padding: 16,
                borderRadius: 10,
                textAlign: "center",
                fontSize: 16,
                fontWeight: 500,
                // background: "#fff",
                background: "#FFC408",
                color: "#212121",
              }}
              href="https://webapp.spotme.com/login/sanofi17/escaping-from-pain"
              target="_blank">
              Register for free to watch livestream<br />FIMS 2024: Unlock Peak Performance
            </a> */}

            <RoomListHeading>
              <BrainIcon />
              {t("room:discovery.quiz")}
            </RoomListHeading>

            <RoomListWrapper>
              <Link to={`/room/quiz/${roomId}`}>
                <img style={{ width: "100%" }} src={quizCardImgSrc} alt="quiz" />
              </Link>
            </RoomListWrapper>
          </>
        )}

        <RoomListHeading>
          <FeedIcon />
          {t("room:discovery.feed")}
        </RoomListHeading>

        {isFiltersAvailable && (
          <div style={{ padding: "0 16px 16px" }}>
            <ContentFilterButton
              active={!!specialtyFilters?.length}
              onClick={() => setFilterPanelVisible(true)}
            />
          </div>
        )}

        {isLoadingArticles ? (
          <FadeIn key="loading">
            <RoomListWrapper>
              {[...Array(10)].map((_, index) => (
                <SkeletonContentCard key={index} />
              ))}
            </RoomListWrapper>
          </FadeIn>
        ) : (
          <FadeIn key="content">
            <InfiniteScroll
              dataLength={articles.length}
              next={() => loadArticles()}
              hasMore={articles.length < totalArticles}
              pullDownToRefresh={false}
              loader={null}
            >
              <RoomListWrapper>
                {!articles.length && (
                  <span className="no-results">
                    {t("room:discovery.noResults")}
                  </span>
                )}
                {articles.map((article, index) => {
                  // TODO: Analytics item list for rooms.
                  return (
                    <ArticleCard
                      key={article._id}
                      article={article}
                      analyticsListName={
                        AnalyticsItemList.DISCOVERY_MAIN_CAROUSEL
                      }
                      onClick={() => {
                        setSwipeCache(articles, index);
                        setPageScrollY(window.scrollY);
                      }}
                    />
                  );
                })}
              </RoomListWrapper>
            </InfiniteScroll>
          </FadeIn>
        )}
        {isTakedaRoom && (
          <div
            className={`fixed-footer ${isNativeIOS ? "safe-area-space" : ""}`}
          >
            {t("room:discovery.takedaRoomFooter")}
          </div>
        )}
      </Wrapper>

      <RoomOptionsPanel
        roomId={roomId}
        isVisible={isOptionsPanelVisible}
        onClose={() => setOptionsPanelVisible(false)}
      />

      <TakedaDisclaimerModal
        visible={isDisclaimerVisible}
        onPress={handleDisclaimerAccept}
        roomLang="en"
      />

      <DownloadAppModal />

      {currentRoom?.mainSpecialty ? (
        <SubSpecialtyFilterPanel
          specialty={currentRoom.mainSpecialty}
          subSpecialties={currentRoom.tags ?? []}
          isOpen={isFilterPanelVisible}
          onClose={() => setFilterPanelVisible(false)}
          onSave={() => handleChangeFilters()}
        />
      ) : (
        <SpecialtyFilterPanel
          isOpen={isFilterPanelVisible}
          onClose={() => setFilterPanelVisible(false)}
          onSave={() => handleChangeFilters()}
        />
      )}

    </>
  );
}

const Wrapper = styled(RoomPageWrapper)`
  .mini-playlist-scroller-container {
    background-color: white;
  }

  .filter-icon-wrapper {
    margin: 0 auto 0 0;
    padding: 0 0 0 16px;
  }

  .filter-icon {
    path {
      fill: white;
    }
    &.active {
      path {
        fill: #ffc408;
      }
    }
  }

  .fixed-footer {
    color: #ed1c24;
    font-family: Inter;
    font-size: 11px;
    font-weight: 600;
    line-height: 12.1px;
    text-align: center;

    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 12px 0;
    background: #f9f9f9;
    z-index: 999;

    &.safe-area-space {
      padding-bottom: 36px;
    }
  }
`;
