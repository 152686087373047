import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useLangContext } from "../../../domains/app/contexts/lang.context";
import { useRoomContext } from "../../../domains/app/contexts/room.context";
import { PlaylistModel } from "../../../domains/playlist/playlist.types";
import { useCurrentUser } from "../../../domains/user/hooks/useCurrentUser";
import { useCertifiedPlaylistsWithCache } from "../../../domains/playlist/endpoints/getCertifiedPlaylists";
import { usePlaylistCacheCertified } from "../../../domains/playlist/hooks/usePlaylistCache";
import { useReadyState } from "../../../domains/app/hooks/useReadyState";
import HomeCarousel from "./HomeCarousel";
import PlaylistCard from "../../playlists/PlaylistCard";
import CarouselIndicator from "../../app/CarouselIndicator";
import CustomIcon from "../../CustomIcon";
import SkeletonContentCard from "../../profile/SkeletonContentCard";
import styled from "styled-components";
import { ReactComponent as BecomeLemonArrow } from "../../../assets/icons/become-lemon-arrow.svg";

export default function CertifiedPlaylistCarousel() {
  const history = useHistory();
  const { activeLang, t } = useLangContext();
  const { isInRoom } = useRoomContext();
  const { currentUser } = useCurrentUser();
  const { list: playlists } = usePlaylistCacheCertified();
  const getCertifiedPlaylists = useCertifiedPlaylistsWithCache();
  const { setAsReady, useReadyEffect } = useReadyState();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!isInRoom) {
        if (playlists.length === 0) await loadPlaylists();
        setAsReady();
      }
    })();
  }, [isInRoom]);

  useReadyEffect(() => {
    loadPlaylists();
  }, [activeLang]);

  async function loadPlaylists() {
    try {
      setLoading(true);

      await getCertifiedPlaylists({
        limit: 5,
        offset: 0,
        language: activeLang,
      });

      setLoading(false);
    } catch (error) {
      console.error("Couldn't get certified playlists.", error);
    }
  }

  return (
    <>
      {!currentUser?.lemonCertified && (
        <BecomeLemonHomeBlock>
          <span>{t("home:certificationCta.title")}</span>
          <p>
            {t(
              "home:certificationCta.text"
            )}
          </p>
          <BecomeLemonButton onClick={() => history.push("/settings")}>
            <CustomIcon iconName='lemon-certified' />
            {t("settings:settingsPage.certificationOptions.default")}
          </BecomeLemonButton>
        </BecomeLemonHomeBlock>
      )}
      <Container
        className='section-content primary padding'
        style={{ background: "#FFAA00" }}
      >
        <div className='section-title'>
          <h3
            style={{
              display: "flex",
              alignItems: "start",
              gap: 8,
              fontSize: 15,
            }}
          >
            <CustomIcon iconName='lemon-certified-white' />
            {t("home:playlists.certified")}
          </h3>
          <Link to='/discovery/playlists?kol=true' style={{ minWidth: "56px" }}>
            <span>{t("common:action.seeAll")}</span>
          </Link>
        </div>

        <HomeCarousel
          swipeable={playlists.length}
          centerMode={true}
          centerSlidePercentage={100}
          renderIndicator={(_onClickHandler: any, isSelected: boolean) => {
            return <CarouselIndicator active={isSelected} />;
          }}
        >
          {isLoading
            ? [
              <SkeletonContentCard key={0} contentFormat='playlist' />,
              <SkeletonContentCard key={1} contentFormat='playlist' />,
            ]
            : (
              [
                ...playlists.slice(0, 5).map((playlist: PlaylistModel) => (
                  <SlideWrapper key={playlist._id + "carouselplaylist"}>
                    <PlaylistCard playlist={playlist} />
                  </SlideWrapper>
                )),
                !currentUser?.lemonCertified ? (
                  <BecomeLemonSlide key='CTA'>
                    <BecomeLemonArrow />
                    <p>
                      {t(
                        "home:certificationCta.text"
                      )}
                    </p>
                    <BecomeLemonButton onClick={() => history.push("/settings")}>
                      <CustomIcon iconName='lemon-certified' />
                      {t("settings:settingsPage.certificationOptions.default")}
                    </BecomeLemonButton>
                  </BecomeLemonSlide>
                ) : null,
              ].filter(element => !!element)
            )
          }
        </HomeCarousel>
      </Container>
    </>
  );
}

const Container = styled.div`
  .slider-wrapper {
    box-sizing: border-box;
    padding: 0 5px;
  }
`;

const SlideWrapper = styled.div`
  flex: 1;
  display: flex;

  .playlist-card {
    flex: 1;
  }
`;

const BecomeLemonHomeBlock = styled.div`
  background: #ffffff;
  padding: 40px 22px;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    display: block;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.02em;
    text-align: center;
  }

  p {
    margin: 0 24px 24px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
  }
`;

const BecomeLemonSlide = styled.div`
  padding: 16px;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  p {
    margin: 0;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: #212121;
  }

  button {
    line-height: 18px;
  }
`;

const BecomeLemonButton = styled.button`
  padding: 16px 32px;
  border: none;
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #fff3ce;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 11px;
  color: #212121;
`;
