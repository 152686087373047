import { SupportedLanguage } from "../../../interfaces";
import { NectarModel } from "../../nectar/nectar.types";
import { searchApi } from "../search.api";

interface QueryParams {
  search: string;
  limit: number;
  offset: number;
  language: SupportedLanguage;
  scoring?: string;
}

const api = searchApi.injectEndpoints({
  endpoints: (build) => ({
    searchNectars: build.query<any, QueryParams>({
      query: (params) => {
        return {
          url: "/content/search-v2",
          params: {
            ...params,
            content_format: "nectar",
          },
        };
      },
      transformResponse: (response: { hits: { hits: [], total: number, facets: [] } }) => {
        const { hits, total, facets } = response.hits;
        const docs = hits.map(({ _source: {
          id,
          view: {
            slug,
            title,
            question,
            content_format,
            publication_date,
            medical_specialties,
          },
        } }) => {
          const nectar: NectarModel = {
            _id: id,
            slug,
            title,
            question,
            content_format,
            publication_date,
            medical_specialties,
          };
          return nectar;
        });
        return { docs, meta: { total, facets } };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useSearchNectarsQuery,
  useLazySearchNectarsQuery,
} = api;
