import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { SpecialtyModel } from "../../domains/app/app.types";
import { ProfessionModel } from "../../domains/profession/profession.types";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { isUserOnboarded } from "../../domains/user/utils/isUserOnboarded";
import { getProfessionsList } from "../../domains/profession/endpoints/getProfessionsList";
import { patchUser } from "../../domains/user/endpoints/patchUser";
import { useLazyGetCurrentUserQuery } from "../../domains/user/endpoints/getCurrentUser2";
import { getUserStartingPage } from "../../domains/user/utils/getUserStartingPage";
import disableScroll from "disable-scroll";
import OnboardingProfile from "../onboarding/OnboardingProfile";
import OnboardingProfession from "../onboarding/OnboardingProfession";
import OnboardingSpecialty from "../onboarding/OnboardingSpecialty";
import OnboardingSuccessFinal from "../onboarding/OnboardingSuccessFinal";
import { OnboardingSuccessWrapper } from "../../components/onboarding/layout";
import Lottie from "lottie-react";
import JuisciFillTube from "../../assets/animations/tube-filling.json";
import {
  gaEventCompleteOnboarding,
  gaEventViewOnboarding,
} from "../../tools/analytics/authAnalytics";
import { SoundEffect } from "../../interfaces";
import { iosPlaySound } from "../../tools/ios";
import { displayToast } from "../../components/app/AppToast";

interface OnboardingUserData {
  firstName: string;
  lastName: string;
  birthDate: string | null;
  country: string;
  profession: ProfessionModel | null;
  mainSpecialty: SpecialtyModel | null;
}

export default function SignupOnboardingPage() {
  const history = useHistory();
  const { t } = useLangContext();
  const { currentUser } = useCurrentUser();
  const [isLoadingUser, setLoadingUser] = useState(false);
  const [isLoadingProfessions, setLoadingProfessions] = useState(false);
  const [professions, setProfessions] = useState<ProfessionModel[] | []>([]);
  const [userData, setUserData] = useState<OnboardingUserData>({
    firstName: currentUser?.firstname ?? "",
    lastName: currentUser?.lastname ?? "",
    birthDate: currentUser?.birthdate ?? null,
    country: currentUser?.country ?? "",
    profession: currentUser?.profession ?? null,
    mainSpecialty: currentUser?.main_specialty ?? null,
  });
  const [getCurrentUser] = useLazyGetCurrentUserQuery();

  useEffect(() => {
    (async function () {
      try {
        disableScroll.off();
        window.scrollTo(0, 0);

        const { isRedirected } = await preflightUser({
          history,
          authOnly: true,
        });
        if (isRedirected) return;

        if (isUserOnboarded(currentUser)) {
          const path = await getUserStartingPage(currentUser);
          return history.replace(path ?? "/discovery");
        }

        if (!currentUser) {
          // Update user data post-signup
          setLoadingUser(true);
          const res = await getCurrentUser();
          setUserData({
            firstName: res.data?.firstname ?? "",
            lastName: res.data?.lastname ?? "",
            birthDate: res.data?.birthDate ?? null,
            country: res.data?.country ?? "",
            profession: res.data?.profession ?? null,
            mainSpecialty: res.data?.main_specialty ?? null,
          });
          setLoadingUser(false);
        }
        gaEventViewOnboarding();
      } catch (error) {
        console.error("Could't mount onboarding page.", error);
        displayToast(t("error:default"));
        history.replace("/discovery");
      }
    })();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (userData.profession === null && !professions.length) {
      loadProfessions();
    }

    if (
      userData.firstName &&
      userData.lastName &&
      userData.country &&
      userData.profession &&
      userData.mainSpecialty
    ) {
      finalizeOnboarding();
    }
  }, [userData]);

  async function loadProfessions() {
    try {
      setLoadingProfessions(true);
      setProfessions(await getProfessionsList());
      setLoadingProfessions(false);
    } catch (error) {
      console.error("Could't load professions.", error);
      displayToast(t("error:default"));
      history.replace("/login");
    }
  }

  function handleUpdateProfile(payload: {
    firstName: string;
    lastName: string;
    birthDate: string | null;
    country: string;
  }) {
    setUserData({
      ...userData,
      ...payload,
    });
  }

  async function handleUpdateProfession(profession: ProfessionModel) {
    setUserData({
      ...userData,
      profession,
    });
  }

  async function handleUpdateSpecialty(specialty: SpecialtyModel) {
    setUserData({
      ...userData,
      mainSpecialty: specialty,
    });
  }

  async function handleCancelProfession() {
    setUserData({
      ...userData,
      profession: null,
    });
  }

  async function handleCancelSpecialty() {
    setUserData({
      ...userData,
      mainSpecialty: null,
    });
  }

  async function finalizeOnboarding() {
    try {
      await patchUser({
        firstname: userData.firstName,
        lastname: userData.lastName,
        birthDate: userData.birthDate,
        country: userData.country,
        profession: userData.profession?.uid,
        main_specialty: userData.mainSpecialty?.uid,
      });
      const user = await getCurrentUser().unwrap();

      setTimeout(() => {
        iosPlaySound(SoundEffect.LIKE);
        gaEventCompleteOnboarding();
      }, 500);

      setTimeout(async () => {
        // NOTE: Clear fields related to a new account
        localStorage.removeItem("seenDownloadAppModal");
        localStorage.removeItem("hasVisitedProfilePage")

        const path = await getUserStartingPage(user);
        history.replace(path ?? "/foryou");
      }, 3000);
    } catch (error) {
      console.error("Couldn't finalize onboarding.", error);
      displayToast(t("error:default"));
      history.replace("/login");
    }
  }

  if (isLoadingUser || isLoadingProfessions) {
    return (
      <OnboardingSuccessWrapper>
        <Lottie
          className='lottie-animation'
          style={{
            width: 64,
            minHeight: "70dvh",
          }}
          animationData={JuisciFillTube}
          loop={true}
          autoplay={true}
        />
      </OnboardingSuccessWrapper>
    );
  }

  if (
    [userData.firstName, userData.lastName, userData.country].some(
      (field: string) => field.length === 0
    )
  ) {
    return (
      <OnboardingProfile userData={userData} onSubmit={handleUpdateProfile} />
    );
  }

  if (!userData.profession) {
    return (
      <OnboardingProfession
        professions={professions}
        onSubmit={handleUpdateProfession}
      />
    );
  }

  if (!userData.mainSpecialty) {
    return (
      <OnboardingSpecialty
        profession={userData.profession}
        onCancel={handleCancelProfession}
        onSubmit={handleUpdateSpecialty}
      />
    );
  }

  return <OnboardingSuccessFinal />;
}
