import { Route, Switch } from "react-router-dom";
import SplashPage from "./view/static/SplashPage";
import WelcomePage from "./view/welcome/WelcomePage";
import HomePage from "./view/home/HomePage";
import homeSpecialties from "./view/home/homeSpecialties";
import homeVideos from "./view/home/homeVideos";
import HomeContentPublicationsPage from "./view/home/content/HomeContentPublicationsPage";
import PlaylistsListPage from "./view/playlist/PlaylistsListPage";
import HomeContentNectarsPage from "./view/home/content/HomeContentNectarsPage";
import ForYouPage from "./view/forYou/ForYouPage";
import ForYouArticleStoryPage from "./view/forYou/ForYouArticleStoryPage";
import ForYouNectarStoryPage from "./view/forYou/ForYouNectarStoryPage";
import VideoStoryListPage from "./view/videoPage/VideoStoryListPage";
import VideoStoryFeed from "./view/videoPage/VideoStoryFeed";
import VideoArticlePage from "./view/videoPage/VideoArticlePage";
import PostPage from "./view/post/PostPage";
import NectarPage from "./view/nectar/NectarPage";
import JournalPage from "./view/journal/JournalPage";
import PlaylistPage from "./view/playlist/PlaylistPage";
import OrganizationPage from "./view/organizationPage/OrganizationPage";
import SignupPage from "./view/signup/SignupPage";
import PreSignupPage from "./view/signup/PreSignupPage";
import MagicLinkPage from "./view/signupPage/magicLinkPage";
import NewPasswordPage from "./view/signupPage/newPassword";
import SignupOnboardingPage from "./view/signup/SignupOnboardingPage";
import LoginPage from "./view/loginPage/LoginPage";
import PrevLoginPage from "./view/loginPage/PrevLoginPage";
import ResetPage from "./view/loginPage/ResetPage";
import ConfirmResetPage from "./view/loginPage/confirmResetPage";
import LoginVideoPage from "./view/loginPage/LoginVideoPage";
import ProfilePage from "./view/profile/ProfilePage";
import NotificationCenter from "./view/notificationsPage/NotificationCenter";
import NotificationRouter from "./view/notificationsPage/notificationRouter";
import MessageListPage from "./view/message/MessageListPage";
import NewMessagePage from "./view/message/NewMessagePage";
import DirectMessagePage from "./view/message/DirectMessagePage";
import ProfileJournals from "./view/profile/profileJournals";
import ProfilePlaylists from "./view/profile/profilePlaylists";
import CreatePlaylist from "./view/profile/createPlaylist";
import ProfilePlaylistPage from "./view/profile/playlistPage";
import ProfileGamification from "./view/profile/gamification/profileGamification";
import SettingsPage from "./view/settings/SettingsPage";
import requestCertificationPage from "./view/settings/requestCertificationPage";
import EditProfilePage from "./view/editProfilePage/EditProfilePage";
import EditAvatarPage from "./view/editProfilePage/editAvatarPage";
import EditPreferredContentPage from "./view/editProfilePage/editPreferredContentPage";
import SettingsEditSecurityPage from "./view/settings/SettingsEditSecurityPage";
import SettingsEditLanguagePage from "./view/settings/SettingsEditLanguagePage";
import SettingsEditAccountPage from "./view/settings/SettingsEditAccountPage";
import PolicyPage from "./view/settings/PolicyPage";
import TermsPage from "./view/settings/TermsPage";
import GlobalSearchPage from "./view/search/GlobalSearchPage";
import CategorySearchPage from "./view/search/CategorySearchPage";
import GPTDiscoverPage from "./view/gpt/GPTDiscoverPage";
import GPTNectarPage from "./view/gpt/GPTNectarPage";
import GPTNewChatPage from "./view/gpt/GPTNewChatPage";
import GPTConversationPage from "./view/gpt/GPTConversationPage";
import GPTChatSourcesPage from "./view/gpt/GPTChatSourcesPage";
import GPTChatHistoryPage from "./view/gpt/GPTChatHistoryPage";
import RoomDiscoveryPage from "./view/room/RoomDiscoveryPage";
import RoomForYouPage from "./view/room/RoomForYouPage";
import RoomHighlightsPage from "./view/room/RoomHighlightsPage";
import RoomShareQrCodePage from "./view/room/RoomShareQrCodePage";
import RoomTermsPrivacyPage from "./view/room/RoomTermsPrivacyPage";
import RoomQuizListPage from "./view/room/RoomQuizListPage";
import RoomQuizSlidesPage from "./view/room/RoomQuizSlidesPage";
import AudioRoomHomePage from "./view/room/audio/AudioRoomHomePage";
import AudioRoomTopicPage from "./view/room/audio/AudioRoomTopicPage";
import AudioRoomPlayerPage from "./view/room/audio/AudioRoomPlayerPage";
import InfographicPage from "./view/infographicPage/InfographicPage";
import ErrorPage from "./view/static/ErrorPage";
import QrCodeInvitePage from "./view/invite/QrCodeInvitePage";
import ShareCodeInvitePage from "./view/invite/ShareCodeInvitePage";

// TODO: Change to .tsx once some pages are updated.

export default function AppRoutes({ onSplashComplete }) {
  return (
    <Switch>
      <Route exact path="/">
        <SplashPage
          onComplete={() => onSplashComplete()}
        />
      </Route>

      <Route path="/welcome" component={WelcomePage} />

      <Route exact path="/discovery" component={HomePage} />
      <Route
        path="/discovery/specialties"
        component={homeSpecialties}
      />
      <Route path="/discovery/videos" component={homeVideos} />
      <Route
        exact
        path="/discovery/publications"
        component={HomeContentPublicationsPage}
      />
      <Route
        path="/discovery/publications/:slug"
        component={HomeContentPublicationsPage}
      />
      <Route
        path="/discovery/playlists"
        component={PlaylistsListPage}
      />
      <Route
        path="/discovery/nectars"
        component={HomeContentNectarsPage}
      />

      <Route exact path="/foryou" component={ForYouPage} />
      <Route
        path="/foryou/story/article/:slug"
        component={ForYouArticleStoryPage}
      />
      <Route
        path="/foryou/story/nectar/:slug"
        component={ForYouNectarStoryPage}
      />

      <Route exact path="/video/:slug" component={VideoArticlePage} />
      <Route
        path="/videos/stories"
        component={VideoStoryListPage}
      />
      <Route path="/video/story/:slug" component={VideoStoryFeed} />

      <Route path="/post/:slug" component={PostPage} />
      <Route path="/nectar/:slug" component={NectarPage} />
      <Route path="/journal/:uid" component={JournalPage} />
      <Route
        path="/playlist/public/:id"
        component={PlaylistPage}
      />
      <Route path="/company/:id" component={OrganizationPage} />

      <Route exact path="/signup" component={SignupPage} />
      <Route path="/signup/pre" component={PreSignupPage} />
      <Route path="/signup/magiclink" component={MagicLinkPage} />
      <Route
        path="/signup/newpassword"
        component={NewPasswordPage}
      />
      <Route
        path="/signup/onboarding"
        component={SignupOnboardingPage}
      />

      <Route exact path="/invite/qrcode" component={QrCodeInvitePage} />
      <Route path="/invite/:inviteCode" component={ShareCodeInvitePage} />

      <Route exact path="/login" component={LoginPage} />
      <Route path="/login/prev" component={PrevLoginPage} />
      <Route exact path="/login/reset" component={ResetPage} />
      <Route
        path="/login/reset/confirm"
        component={ConfirmResetPage}
      />

      <Route
        exact
        path="/profile"
        render={(props) => <ProfilePage {...props} isMe />}
      />

      <Route
        exact
        path="/profile/user/:id"
        render={(props) => <ProfilePage {...props} isMe={false} />}
      />

      <Route
        exact
        path="/profile/notifications"
        component={NotificationCenter}
      />
      <Route
        path="/profile/notifications/:id"
        component={NotificationRouter}
      />

      <Route
        exact
        path="/profile/messages"
        component={MessageListPage}
      />
      <Route
        path="/profile/messages/new"
        component={NewMessagePage}
      />
      <Route
        exact
        path="/profile/messages/conversation"
        component={DirectMessagePage}
      />
      <Route
        path="/profile/messages/conversation/:id"
        component={DirectMessagePage}
      />

      <Route path="/profile/journals" component={ProfileJournals} />
      <Route
        exact
        path="/profile/playlist"
        component={ProfilePlaylists}
      />
      <Route
        exact
        path="/profile/playlist/create"
        component={CreatePlaylist}
      />
      <Route
        path="/profile/playlist/:id"
        component={ProfilePlaylistPage}
      />

      {/* On garde cette route au cas où */}
      {/* TODO: ??? */}
      <Route
        exact
        path="/profile/user/:uid/playlist/:id"
        component={ProfilePlaylistPage}
      />

      <Route
        path="/profile/game/activity"
        component={ProfileGamification}
      />
      <Route
        path="/user/game/activity/:user_id"
        component={ProfileGamification}
      />

      <Route exact path="/settings" component={SettingsPage} />
      <Route
        path="/settings/request/certification"
        component={requestCertificationPage}
      />
      <Route
        exact
        path="/settings/edit/profile"
        component={EditProfilePage}
      />
      <Route
        path="/settings/edit/profile/avatar"
        component={EditAvatarPage}
      />
      <Route
        path="/settings/edit/profile/preferredContent"
        component={EditPreferredContentPage}
      />
      <Route
        path="/settings/edit/security"
        component={SettingsEditSecurityPage}
      />
      <Route
        path="/settings/edit/language"
        component={SettingsEditLanguagePage}
      />
      <Route
        path="/settings/account"
        component={SettingsEditAccountPage}
      />

      <Route path="/policy" component={PolicyPage} />
      <Route path="/terms" component={TermsPage} />

      <Route exact path="/search" component={GlobalSearchPage} />
      <Route
        path="/search/:category"
        component={CategorySearchPage}
      />

      <Route
        exact
        path="/gpt/discover"
        component={GPTDiscoverPage}
      />
      <Route path="/gpt/discover/:slug" component={GPTNectarPage} />
      <Route path="/gpt/chat/new" component={GPTNewChatPage} />
      <Route
        path="/gpt/chat/conversation/:id"
        component={GPTConversationPage}
      />
      <Route
        path="/gpt/chat/sources"
        component={GPTChatSourcesPage}
      />
      <Route
        path="/gpt/chat/history/"
        component={GPTChatHistoryPage}
      />

      <Route
        exact
        path="/infographic/:slug"
        component={InfographicPage}
      />

      {/* NOTE: Room pages must always contain "/room" and the roomId. */}

      <Route
        path="/room/discovery/:roomId"
        component={RoomDiscoveryPage}
      />

      <Route
        exact
        path="/room/foryou/:roomId"
        component={RoomForYouPage}
      />
      <Route
        path="/room/foryou/:roomId/story/article/:slug"
        component={ForYouArticleStoryPage}
      />
      <Route
        path="/room/foryou/:roomId/story/nectar/:slug"
        component={ForYouNectarStoryPage}
      />

      <Route
        path="/room/highlights/:roomId"
        component={RoomHighlightsPage}
      />

      <Route
        path="/room/playlists/:roomId"
        component={PlaylistsListPage}
      />

      <Route
        path="/room/share/qrcode/:roomId"
        component={RoomShareQrCodePage}
      />

      <Route
        path="/room/terms-privacy/:roomId"
        component={RoomTermsPrivacyPage}
      />

      <Route
        exact
        path="/room/quiz/:roomId"
        component={RoomQuizListPage}
      />

      <Route
        path="/room/quiz/:roomId/:quizId"
        component={RoomQuizSlidesPage}
      />

      <Route
        exact
        path="/room/audio/home/:roomId"
        component={AudioRoomHomePage}
      />

      <Route
        exact
        path="/room/audio/:roomId/topic/:topic"
        component={AudioRoomTopicPage}
      />

      <Route
        path="/room/audio/:roomId/player/:slug/:time?"
        component={AudioRoomPlayerPage}
      />

      <Route>
        <ErrorPage />
      </Route>
    </Switch>
  );
}